*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: Lexend Deca, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.visible {
  visibility: visible;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.-z-10 {
  z-index: -10;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.h-full {
  height: 100%;
}

.h-6 {
  height: 1.5rem;
}

.h-40 {
  height: 10rem;
}

.h-36 {
  height: 9rem;
}

.h-\[5rem\] {
  height: 5rem;
}

.h-16 {
  height: 4rem;
}

.h-8 {
  height: 2rem;
}

.min-h-full {
  min-height: 100%;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-\[9rem\] {
  width: 9rem;
}

.min-w-full {
  min-width: 100%;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-\[680px\] {
  max-width: 680px;
}

.max-w-none {
  max-width: none;
}

.grow {
  flex-grow: 1;
}

.origin-\[70\%_70\%\] {
  transform-origin: 70% 70%;
}

.translate-x-\[-24px\] {
  --tw-translate-x: -24px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-26px\] {
  --tw-translate-y: -26px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes fade-in-out-1 {
  0% {
    opacity: 0;
    display: inline;
  }

  5% {
    opacity: 1;
    display: inline;
  }

  15% {
    opacity: 1;
    display: inline;
  }

  20% {
    opacity: 0;
    display: none;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.animate-fade-text-slow-1 {
  animation: 20s ease-in-out infinite fade-in-out-1;
}

@keyframes fade-in-out-2 {
  0% {
    opacity: 0;
    display: none;
  }

  20% {
    opacity: 0;
    display: inline;
  }

  25% {
    opacity: 1;
    display: inline;
  }

  35% {
    opacity: 1;
    display: inline;
  }

  40% {
    opacity: 0;
    display: none;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.animate-fade-text-slow-2 {
  animation: 20s ease-in-out infinite fade-in-out-2;
}

@keyframes fade-in-out-3 {
  0% {
    opacity: 0;
    display: none;
  }

  40% {
    opacity: 0;
    display: inline;
  }

  45% {
    opacity: 1;
    display: inline;
  }

  55% {
    opacity: 1;
    display: inline;
  }

  60% {
    opacity: 0;
    display: none;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.animate-fade-text-slow-3 {
  animation: 20s ease-in-out infinite fade-in-out-3;
}

@keyframes fade-in-out-4 {
  0% {
    opacity: 0;
    display: none;
  }

  60% {
    opacity: 0;
    display: inline;
  }

  65% {
    opacity: 1;
    display: inline;
  }

  75% {
    opacity: 1;
    display: inline;
  }

  80% {
    opacity: 0;
    display: none;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.animate-fade-text-slow-4 {
  animation: 20s ease-in-out infinite fade-in-out-4;
}

@keyframes fade-in-out-5 {
  0% {
    opacity: 0;
    display: none;
  }

  80% {
    opacity: 0;
    display: inline;
  }

  85% {
    opacity: 1;
    display: inline;
  }

  95% {
    opacity: 1;
    display: inline;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.animate-fade-text-slow-5 {
  animation: 20s ease-in-out infinite fade-in-out-5;
}

@keyframes fade-image {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  20% {
    opacity: 0;
  }

  97% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fade-image-5 {
  animation: 20s ease-in-out 16s infinite fade-image;
}

.animate-fade-image-4 {
  animation: 20s ease-in-out 12s infinite fade-image;
}

.animate-fade-image-3 {
  animation: 20s ease-in-out 8s infinite fade-image;
}

.animate-fade-image-2 {
  animation: 20s ease-in-out 4s infinite fade-image;
}

.animate-fade-image-1 {
  animation: 20s ease-in-out infinite fade-image;
}

@keyframes wave-loop {
  0% {
    transform: rotate(0);
  }

  7% {
    transform: rotate(14deg);
  }

  14% {
    transform: rotate(-8deg);
  }

  20% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-4deg);
  }

  33% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.animate-hand-wave {
  animation: 3s infinite wave-loop;
}

.animate-fade-text-1 {
  animation: 15s ease-in-out infinite fade-in-out-1;
}

.animate-fade-text-2 {
  animation: 15s ease-in-out infinite fade-in-out-2;
}

.animate-fade-text-3 {
  animation: 15s ease-in-out infinite fade-in-out-3;
}

.animate-fade-text-4 {
  animation: 15s ease-in-out infinite fade-in-out-4;
}

.animate-fade-text-5 {
  animation: 15s ease-in-out infinite fade-in-out-5;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-3 {
  gap: .75rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.place-self-end {
  place-self: end;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.justify-self-center {
  justify-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-xl {
  border-radius: .75rem;
}

.border-2 {
  border-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-brand {
  --tw-border-opacity: 1;
  border-color: rgb(242 141 72 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-opacity-5 {
  --tw-border-opacity: .05;
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-brand-light {
  --tw-bg-opacity: 1;
  background-color: rgb(249 240 237 / var(--tw-bg-opacity));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-orange-200 {
  --tw-gradient-from: #fed7aa;
  --tw-gradient-to: #fed7aa00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-orange-100 {
  --tw-gradient-to: #ffedd500;
  --tw-gradient-stops: var(--tw-gradient-from), #ffedd5, var(--tw-gradient-to);
}

.to-orange-200 {
  --tw-gradient-to: #fed7aa;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-scale-down {
  object-fit: scale-down;
}

.p-12 {
  padding: 3rem;
}

.p-6 {
  padding: 1.5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.text-left {
  text-align: left;
}

.font-body {
  font-family: Lato, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-sans {
  font-family: Lexend Deca, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-lg {
  font-size: 1.125rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-sm {
  font-size: .875rem;
}

.text-5xl {
  font-size: 2.5rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.leading-tight {
  line-height: 1.25;
}

.leading-loose {
  line-height: 2;
}

.leading-8 {
  line-height: 2rem;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-brand-light {
  --tw-text-opacity: 1;
  color: rgb(249 240 237 / var(--tw-text-opacity));
}

.text-brand {
  --tw-text-opacity: 1;
  color: rgb(242 141 72 / var(--tw-text-opacity));
}

.placeholder-black::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(0 0 0 / var(--tw-placeholder-opacity));
}

.placeholder-opacity-40::placeholder {
  --tw-placeholder-opacity: .4;
}

.opacity-70 {
  opacity: .7;
}

.opacity-60 {
  opacity: .6;
}

.opacity-50 {
  opacity: .5;
}

.opacity-0 {
  opacity: 0;
}

.opacity-80 {
  opacity: .8;
}

.opacity-75 {
  opacity: .75;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:bg-brand:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(242 141 72 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: .2;
}

@media (width >= 640px) {
  .sm\:hidden {
    display: none;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:order-last {
    order: 9999;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mb-1 {
    margin-bottom: .25rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:grid {
    display: grid;
  }

  .md\:h-screen {
    height: 100vh;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-40 {
    height: 10rem;
  }

  .md\:min-h-\[48rem\] {
    min-height: 48rem;
  }

  .md\:min-h-screen {
    min-height: 100vh;
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:justify-self-start {
    justify-self: start;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
  }

  .md\:text-5xl {
    font-size: 2.5rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
  }

  .md\:font-bold {
    font-weight: 700;
  }
}

/*# sourceMappingURL=index.8f800107.css.map */
